import * as React from "react"
import Meta from "../utilities/seo"
import styled from "styled-components"
import Splash from "../microsites/careers/splash"
import Jobs from "../microsites/careers/jobs"
import Footer from "../components/layout/footer"
import Header from "../components/layout/header"

const Main = styled.main`
  position: relative;
`

const Page = () => {
  return (
    <>
      <Meta
        title="Careers"
        description="Join us in building a universe where people can confidently realize their full potential. A universe of opportunities, where children can get an education, our parents can safely retire, and where our dreams can become a reality."
        image={{
          url: "https://res.cloudinary.com/xeno-technologies/image/upload/v1665994656/xeno-culture-day_jriiwf.jpg",
          height: "1280",
          width: "783",
        }}
      />
      {/* <Main>
        <Header />
        <Splash />
        <Jobs />
        <Footer />
      </Main> */}
    </>
  )
}
export default Page

import * as React from "react"
import styled from "styled-components"
import Slideshow from "./slideshow"

const Box = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fff;
`

const Ele = () => {
  return (
    <Box>
      <Slideshow />
    </Box>
  )
}
export default Ele

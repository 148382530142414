import * as React from 'react'
import styled from 'styled-components'

const Box = styled.div``

const Ele = () => {
    return(
    <Box>
        
    </Box>
)} 
 export default Ele
import * as React from "react"
import styled from "styled-components"
import Image from "./images/xeno-culture-day.jpg"

const Box = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background-color: #000;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#fff, rgba(255, 255, 255, 0));
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
  }
`

const Ele = () => {
  return (
    <Box>
      <img src={Image} alt="" />
    </Box>
  )
}
export default Ele
